































































































































.templateList{
    .framePage{
        .el-form{
            .el-input{
                width: 14.5rem;
            }
        }
        .block-title{
            padding-left: 10px;
            margin-bottom: 20px;
            position: relative;
            &::after{
                content: "";
                display: block;
                width: 8px;
                height: 16px;
                background-color: #5260CF;
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
    }
}
